
@import url('https://fonts.googleapis.com/css2?family=GFS+Didot&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caudex&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caudex&family=EB+Garamond&family=El+Messiri&family=Marcellus&display=swap');


body{
    background-color:rgb(255, 247, 237);
    margin-left: 8px;
    margin-right: 8px;
}
#greek, #latin{
    display:none;
}

h4 {
    margin-top: 8px;
    margin-bottom: 8px;
}

h2 {
    margin-top: 10px;
}

input {
    margin-left: 8px;
    margin-right: 8px;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedSecondary {
    color:rgb(152, 19, 46);
    border-color:rgb(152, 19, 46);
    margin-left: 5px;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
    margin-left: 5px;
    color:rgb(20, 85, 73);
    border-color:rgb(20, 85, 73);
}

.lang-input {
    height:24px;
    border-radius:4px;
    font-size:20px;
    vertical-align: middle;
    line-height:28px;
    outline: none;
    padding: 3px 0px 8px 4px;
    margin: 3px 1px 3px 10px;
    border: 1px solid #DDDDDD;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    transition: all 0.30s ease-in-out;
    width: 30vw;
}

.lang-input:focus{
    outline-color:cadetblue;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    /* padding: 3px 0px 3px 3px; */
    /* margin: 5px 1px 3px 0px; */
    border: 1px solid rgba(81, 203, 238, 1);
}

#greek-label, #latin-label {
    display:inline-block;
    font-size:20px;
    text-align:left;
    height:100%;
}

#form-container {
    
    width:30vw;
    display:flex;
}

.lang-form {
    display:flexbox;
    min-width:30%;
    padding:5px;
}

#latin-cell {
    width: 100%;
}

table {
    max-width: 100%;
}

#gr {
    width: 100px;
} 

#lab {
    width: 20px;
}

#form-container {
    width: 97vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#translation {
    margin-left:15px;
    max-width: 97vw;
}

.phrase-container {
    border: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height:3rem;
    line-height:3rem;
    font-size:2rem;
    flex-wrap: wrap;
    max-width:95%;
    margin:auto;
    margin-top: 15px;
}

.phrase-word {
    margin-left: 0.5rem;
    margin-right:1rem;
}

.phrase-word:hover {
    color:rgb(109, 109, 109);
    cursor:pointer;
    user-select:none;
}

.phrase-word:active {
    color:rgb(145, 145, 145);
}

.loading {
    height: 50px;
    text-align:center;
    line-height: 3.5rem;
    font-size:40px;
    margin-top:20px;
}




.infl {
    color: rgb(21, 39, 40);
    
}

.possibility {
    color:rgb(152, 19, 46);
    font-size:28px;
    font-family:'El Messiri', sans-serif;
}

.long {
    font-size:24px;
    font-family: 'EB Garamond', serif;
    /* font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif */
}

.inflect {
    font-size:24px;
    min-height: 40px;
    line-height: 20px;
    font-family: 'EB Garamond', serif;
    /* display:flex;
    flex-direction:row; */
}
.short {
    font-size:24px;
    min-height: 40px;
    line-height: 30px;
    font-family: 'EB Garamond', serif;
}

.title {
    font-size:50px;
    margin-bottom: 10px;
    font-family: 'EB Garamond', serif;
}

.head {
    font-family: 'EB Garamond', serif;
    font-size: 30px; 
}

.type {
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size:32px;
    font-style: italic;
    color:rgb(20, 85, 73);
}

.trans-container {
    border-bottom: 3px;
    border-bottom-style:solid;
    border-bottom-color:darkslategray;
}

.anchor-class {
    font-weight:bold;
    color:rgb(152, 19, 46);
}

.anchor-class:hover {
    color:rgb(99, 17, 21);
}

@media only screen and (max-width: 600px) {

    .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedSecondary {
        color:rgb(152, 19, 46);
        border-color:rgb(152, 19, 46);
        margin-top:5px;
        margin-left:0;
    }
    
    .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
        margin-top:5px;
        margin-left:0;
        color:rgb(20, 85, 73);
        border-color:rgb(20, 85, 73);
    }
    
    .lang-form {
        display:flex;
        flex-direction:column;
        align-content:center;
    }
    .lang-input {
        margin-left: 5px;
        margin-right:5px;
        width: 90%;
        align-self:center;
    }

    .loading {
        height: 50px;
        text-align:center;
        line-height: 3.5rem;
        font-size:30px;
        margin-top:20px;
    }

    .phrase-container {
        font-size:1.5rem;
        min-height:2.4rem;
        line-height:2.4rem;
    }

    .infl {
        color: rgb(21, 39, 40);
    }
    
    .possibility {
        color:rgb(152, 19, 46);
        font-size:20px;
        font-family:'El Messiri', sans-serif;
    }
    
    .inflect, .short, .long {
        font-size:18px;
        font-family: 'EB Garamond', serif;
        /* font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif */
    }
    
    .title {
        font-size:40px;
        margin-bottom: 10px;
        font-family: 'EB Garamond', serif;
    }
    
    .head {
        font-family: 'EB Garamond', serif;
        font-size: 22px; 
    }
    
    .type {
        font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-size:24px;
        font-style: italic;
        color:rgb(20, 85, 73);
    }
    
    .trans-container {
        border-bottom: 3px;
        border-bottom-style:solid;
        border-bottom-color:darkslategray
    }
    
    .anchor-class {
        font-weight:bold;
        color:rgb(152, 19, 46)
    }
    
    .anchor-class:hover {
        color:rgb(99, 17, 21)
    }
  }

  @media only screen and (max-width: 900px) {
    .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedSecondary {
        color:rgb(152, 19, 46);
        border-color:rgb(152, 19, 46);
        margin-top:5px;
        margin-left:0;
    }
    
    .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
        margin-top:5px;
        margin-left:0;
        color:rgb(20, 85, 73);
        border-color:rgb(20, 85, 73);
    }
    .lang-form {
        display:flex;
        flex-direction:column;
        align-content:center;
    }
    .lang-input {
        margin-left: 1px;
    }

    .loading {
        height: 50px;
        text-align:center;
        line-height: 3.5rem;
        font-size:30px;
        margin-top:20px;
    }

    .phrase-container {
        font-size:1.5rem;
        min-height:2.4rem;
        line-height:2.4rem;
    }

    .infl {
        color: rgb(21, 39, 40);
    }
    
    .possibility {
        color:rgb(152, 19, 46);
        font-size:20px;
        font-family:'El Messiri', sans-serif;
    }
    
    .inflect, .short, .long {
        font-size:18px;
        font-family: 'EB Garamond', serif;
        /* font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif */
    }
    
    .title {
        font-size:40px;
        margin-bottom: 10px;
        font-family: 'EB Garamond', serif;
    }
    
    .head {
        font-family: 'EB Garamond', serif;
        font-size: 22px; 
    }
    
    .type {
        font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        font-size:24px;
        font-style: italic;
        color:rgb(20, 85, 73);
    }
    
    .trans-container {
        border-bottom: 3px;
        border-bottom-style:solid;
        border-bottom-color:darkslategray
    }
    
    .anchor-class {
        font-weight:bold;
        color:rgb(152, 19, 46)
    }
    
    .anchor-class:hover {
        color:rgb(99, 17, 21)
    }
  }